import FieldRules from './validators/field-rules.js'

export default () => {
  return {
    name: null,
    surnames: null,
    email: null,
    phoneNumber: null,
    // message: null,
    model: null,
    dealershipId: null,
    date: null,
    department: 'SERVICIO',
    plates: null,
    $rules: {
      name: new FieldRules().required().get(),
      surnames: new FieldRules().required().get(),
      email: new FieldRules()
        .required()
        .email()
        .get(),
      phoneNumber: new FieldRules()
        .required()
        .maxLength(10)
        .minLength(10)
        .get(),
      // message: new FieldRules().required().get(),
      model: new FieldRules().required().get(),
      dealershipId: new FieldRules().required().get(),
      date: new FieldRules().required().get(),
      plates: new FieldRules()
        .required()
        .maxLength(7)
        .minLength(5)
        .get()
    }
  }
}
